import React, { forwardRef, useEffect } from 'react'

export type PoiTagProps = unknown

const PoiTag = forwardRef<HTMLDivElement, PoiTagProps>((props, ref) => {
  useEffect(() => {
    if (!document.querySelector('#Services123_root')) {
      window.location.reload()
    }
  }, [])

  return (
    <div
      className="Services123PoiTag"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: `
        <script>
function getParameterByName(name) {
  name = name.replace(/[\\[]/, "\\\\[").replace(/[\\]]/, "\\\\]");
  var regexS = "[\\?\u0026]" +name+ "=([^\u0026#]*)";
  var regex = new RegExp(regexS);
  var results = regex.exec(window.location.search);
  if(results == null) {
    return "";
  }
  else {
    return decodeURIComponent(results[1].replace(/\\+/g, " "));
  }
  };
  var service123_tagtype = "searchlistmap";
  var service123_location = "";
  var service123_search = getParameterByName('123Service_search');
  var service123_where = getParameterByName('123Service_where');
  var service123_center = getParameterByName('123Service_center');
  var service123_theme = "baedeker";
  var service123_sort = "dist";
  var service123_featured = "1";
  var service123_itemsperpage = "4";
  var service123_startindex = getParameterByName('123Service_startindex');
  var service123_control1 = "FalkBigMapControl,right,5,5";
  var service123_control2 = "FalkMapSwitchControl,left,0,0";
  var service123_location_hint = getParameterByName('123Service_location_hint');
  if(service123_where==''){
    var service123_sort = "frel";
    var service123_featured = "1";
  }
      </script>
      <script src="https://services.mairdumont.com/123services/js/show_tag.js"></script>
      `,
      }}
      ref={ref}
    />
  )
})

PoiTag.displayName = 'PoiTag'

export default PoiTag

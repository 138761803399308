import { Typography } from '@material-ui/core'
import { PageProps } from 'gatsby'
import React, { useEffect } from 'react'
import Layout from '../components/layout'
import PoiTag from '../components/poiTag'

type SightsProps = PageProps

const title = 'Premium Locations'

const Sights = ({ location }: SightsProps) => {
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (window.SDG) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.SDG.Publisher.setZone('content')
    }
  }, [])
  return (
    <Layout
      location={location}
      seoProps={{
        title,
        description: 'TODO',
      }}
    >
      <Typography component="h1" variant="h2" gutterBottom>
        {title}
      </Typography>

      <PoiTag />
    </Layout>
  )
}

export default Sights
